import React, { useState, useMemo, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { signIn, signOut, getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import Dashboard from './dashboard';
import './index.css';
import ViolationSummary from './violationSummary';
import SignIn from './user-registration/signin';
import ChangePassword from './user-registration/changePassword';
import ModifiedGeoJsonViewer from './modifiedGeoJsonViewer';
import ModifiedHeader from './util/Header/modifiedHeader';
import { useNotifications } from './hooks/useNotifications';

const geoJsonFiles = [
  '/geojsonData5.json',
];

function App() {
  const [darkMode, setDarkMode] = useState(() => {
    const savedDarkMode = localStorage.getItem('darkMode');
    return savedDarkMode !== null ? JSON.parse(savedDarkMode) : false;
  });
  const [selectedScenario, setSelectedScenario] = useState('base');
  const [user, setUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [toastNotification, setToastNotification] = useState(null);
  const [isTemporaryPassword, setIsTemporaryPassword] = useState(false);
  const { addNotification, notifications, unreadCount, markAllAsRead, clearNotifications } = useNotifications(user?.email);

  // Add effect to check auth status on load
  useEffect(() => {
    checkAuthStatus();
  }, []);

  const checkAuthStatus = async () => {
    try {
      const currentUser = await getCurrentUser();
      const session = await fetchAuthSession();
      if (currentUser && session) {
        // console.log('Authenticated user:', session);
        setUser({ email: session.tokens.signInDetails.loginId });
        setIsTemporaryPassword(false);
      }
    } catch (error) {
      console.log('No authenticated user');
      setUser(null);
      setIsTemporaryPassword(false);
    }
  };

  const handleScenarioChange = (newScenario) => {
    const scenarioMessages = {
      'base': 'Base Case',
      'er4109': 'Energization (50 kW): P1ULV4109',
      'er4109_1': 'Energization (25 kW): P1ULV4109',
      'er4139': 'Energization (75 kW): P1ULV4139',
      'er7633': 'Energization (75 kW): P1ULV7543',
    };
    
    const message = scenarioMessages[newScenario] || '';
    const notificationMessage = `Scenario changed to: ${message}`;
    
    setToastNotification(notificationMessage);
    setTimeout(() => setToastNotification(null), 2000);
    
    addNotification(notificationMessage);
    setSelectedScenario(newScenario);
  };

  const handleSignIn = async (email, password) => {
    try {
      // First ensure we're signed out
      try {
        await signOut({ global: true });
      } catch (signOutError) {
        console.log('No existing session to sign out');
      }

      const signInResult = await signIn({ 
        username: email, 
        password: password,
        options: {
          authFlowType: "USER_SRP_AUTH"
        }
      });
      
      switch (signInResult.nextStep.signInStep) {
        case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
          setIsTemporaryPassword(true);
          setUser({ email: email });
          sessionStorage.setItem('signInResult', JSON.stringify({
            isNewPasswordRequired: true,
            email: email
          }));
          addNotification('Please change your temporary password');
          break;
          
        case 'DONE':
          setUser({ email: email });
          setIsTemporaryPassword(false);
          setSelectedScenario('base');
          addNotification('Successfully signed in');
          break;
          
        default:
          setErrorMessage('Unexpected authentication state');
          break;
      }
    } catch (error) {
      console.error('Sign in error:', error);
      setErrorMessage('Invalid email or password. Please try again.');
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut({ global: true });
      setUser(null);
      setErrorMessage(null);
      setIsTemporaryPassword(false);
      setSelectedScenario('base');
      sessionStorage.removeItem('signInResult');
      // Clear any other stored auth data
      localStorage.removeItem('lastAuthUser');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  // Add a function to handle route changes
  const handleRouteChange = async () => {
    if (!user) {
      try {
        const currentUser = await getCurrentUser();
        const session = await fetchAuthSession();
        if (currentUser) {
          setUser({ email: session.tokens.signInDetails.loginId });
        }
      } catch (error) {
        console.log('No authenticated user');
      }
    }
  };

  const handlePasswordChange = async () => {
    try {
      setIsTemporaryPassword(false);
      sessionStorage.removeItem('signInResult');
      addNotification('Password successfully changed');
    } catch (error) {
      console.error('Error completing password change:', error);
      addNotification('Error completing password change');
    }
  };

  // Protected Route component
  const ProtectedRoute = ({ children, requiresNewPassword = false }) => {
    useEffect(() => {
      handleRouteChange();
    }, []);

    if (!user) {
      return <Navigate to="/signin" replace />;
    }

    if (isTemporaryPassword && !requiresNewPassword) {
      return <Navigate to="/change-password" replace />;
    }

    if (!isTemporaryPassword && requiresNewPassword) {
      return <Navigate to="/geojson" replace />;
    }

    return children;
  };

  const memoizedRoutes = useMemo(() => (
    <Routes>
      {/* Redirect root to /geojson */}
      <Route 
        path="/" 
        element={<Navigate to="/geojson" replace />} 
      />

      <Route 
        path="/signin" 
        element={
          user ? (
            isTemporaryPassword ? (
              <Navigate to="/change-password" replace />
            ) : (
              <Navigate to="/geojson" replace />
            )
          ) : (
            <SignIn 
              onSignIn={handleSignIn} 
              darkMode={darkMode} 
              errorMessage={errorMessage}
            />
          )
        }
      />
      
      <Route
        path="/change-password"
        element={
          <ProtectedRoute requiresNewPassword>
            <ChangePassword 
              darkMode={darkMode}
              onPasswordChange={handlePasswordChange}
              email={user?.email}
            />
          </ProtectedRoute>
        }
      />
      
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard 
              darkMode={darkMode} 
              selectedScenario={selectedScenario} 
              onScenarioChange={handleScenarioChange} 
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="/geojson"
        element={
          <ProtectedRoute>
            <ModifiedGeoJsonViewer 
              geoJsonFiles={geoJsonFiles} 
              darkMode={darkMode} 
              selectedScenario={selectedScenario} 
              onScenarioChange={handleScenarioChange}
            />
          </ProtectedRoute>
        }
      />

      <Route
        path="/ai-insights"
        element={
          <ProtectedRoute>
            <ViolationSummary darkMode={darkMode} />
          </ProtectedRoute>
        }
      />

      {/* Catch all route - redirect to /geojson */}
      <Route
        path="*"
        element={<Navigate to="/geojson" replace />}
      />
    </Routes>
  ), [darkMode, selectedScenario, user, errorMessage, isTemporaryPassword]);

  return (
    <Router>
      <div className={`min-h-screen ${darkMode ? 'dark' : ''}`}>
        <ModifiedHeader 
          darkMode={darkMode} 
          setDarkMode={setDarkMode} 
          user={user} 
          onSignOut={handleSignOut}
          toastNotification={toastNotification}
          setToastNotification={setToastNotification}
          notifications={notifications}
          unreadCount={unreadCount}
          markAllAsRead={markAllAsRead}
          clearNotifications={clearNotifications}
        />
        <main className="pt-14">
          {memoizedRoutes}
        </main>
      </div>
    </Router>
  );
}

export default App;