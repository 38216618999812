import React, { useState } from 'react';
import { ChevronRight, BatteryCharging, Users, AlertTriangle, Clock, ChevronDown, Zap, GitBranch, Database, Info } from 'lucide-react';

const CustomDropdown = ({ options, value, onChange, darkMode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedOption = options.find(opt => opt.value === value);

  return (
    <div className="relative w-full">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`w-full p-3 flex items-center justify-between rounded-lg ${
          darkMode ? 'bg-white text-black' : 'bg-white text-gray-900'
        } border border-gray-200 dark:border-gray-700`}
      >
        <span>{selectedOption?.label}</span>
        <ChevronDown className={`transform transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </button>
      
      {isOpen && (
        <div className={`absolute z-50 w-full mt-1 rounded-lg shadow-lg ${
          darkMode ? 'bg-gray-800 border border-gray-700' : 'bg-white border border-gray-200'
        }`}>
          {options.map((option) => (
            <button
              key={option.value}
              className={`w-full p-3 text-left hover:bg-blue-100 dark:hover:bg-blue-800 ${
                option.value === value ? 'bg-blue-50 dark:bg-blue-800' : ''
              }`}
              onClick={() => {
                onChange(option.value);
                setIsOpen(false);
              }}
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

const StatCard = ({ label, value, icon: Icon }) => (
  <div className="flex items-center justify-between p-4 rounded-lg bg-gray-50 dark:bg-white-800">
    <div className="flex items-center space-x-3">
      {Icon && (
        <div className="p-2 rounded-full bg-blue-100 dark:bg-blue-900">
          <Icon className="h-5 w-5 text-blue-600 dark:text-blue-400" />
        </div>
      )}
      <div>
        <div className="text-sm text-gray-600 dark:text-gray-600">{label}</div>
        <div className="text-lg font-semibold text-gray-600 dark:text-black">{value}</div>
      </div>
    </div>
  </div>
);

const RecommendationCard = ({ icon: Icon, title, subtitle, powerValue, feedersValue, darkMode }) => (
  <div className={`p-4 rounded-lg border ${
    darkMode ? 'border-gray-50 bg-white' : 'bg-gray-50 bg-white-800'
  } mb-3`}>
    <div className="flex items-center justify-between mb-2">
      <div className="flex items-center space-x-3">
        <div className="p-2 rounded-full bg-blue-100 dark:bg-blue-900">
          <Icon className="h-5 w-5 text-blue-600 dark:text-blue-400" />
        </div>
        <div>
          <h4 className="font-medium text-black">{title}</h4>
          <p className="text-sm text-gray-500 dark:text-gray-600">{subtitle}</p>
        </div>
      </div>
    </div>
    {(powerValue || feedersValue) && (
      <div className="flex space-x-4 mt-2">
        {powerValue && (
          <div>
            <div className="text-lg font-semibold text-black">{powerValue}kW</div>
            <div className="text-sm text-gray-500 dark:text-black">Power</div>
          </div>
        )}
        {feedersValue && (
          <div>
            <div className="text-lg font-semibold text-black">{feedersValue}</div>
            <div className="text-sm text-gray-500 dark:text-black">Feeders</div>
          </div>
        )}
      </div>
    )}
  </div>
);

const scenarios = [
  { value: "base", label: "Base Scenario" },
  { value: "er4109", label: "Energization (50kW): P1ULV4109" },
  { value: "er4109_1", label: "Energization (25kW): P1ULV4109" },
  { value: "er4139", label: "Energization (75kW): P1ULV4139" },
  { value: "er7633", label: "Energization (75kW): P1ULV7543" },
];

const AISummary = ({ darkMode, selectedScenario, onScenarioChange, onExpand, onQueueClick, onPreviewClick, analysisData, isLoading }) => {
  const [activeTab, setActiveTab] = useState('insights');
  const [sortBy, setSortBy] = useState('cost');

  const getAnalysisValues = () => {
    if (!analysisData) {
      return {
        totalViolationsCount: 560,
        underVoltageCount: 340,
        overVoltageCount: 120,
        averageViolationDuration: '3h 53m',
        assetConstraintsCount: 47,
        linesCongestionCount: 89
      };
    }

    return {
      totalViolationsCount: analysisData.totalViolationsCount,
      underVoltageCount: analysisData.underVoltageCount,
      overVoltageCount: analysisData.overVoltageCount,
      averageViolationDuration: analysisData.averageViolationDuration,
      assetConstraintsCount: analysisData.assetConstraintsCount,
      linesCongestionCount: analysisData.linesCongestionCount
    };
  };

  const analysisValues = getAnalysisValues();
  console.log(analysisValues);

  return (
    <div className={`h-full w-85 ${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'} overflow-y-auto`}>
      <div className="p-4">
        <div className="flex space-x-1 p-1 bg-blue-900/20 rounded-xl mb-4">
          <button
            className={`w-full py-2.5 text-sm font-medium leading-5 rounded-lg transition-colors ${
              activeTab === 'insights'
                ? 'bg-white dark:bg-gray-800 shadow text-blue-700 dark:text-blue-400'
                : 'text-gray-600 dark:text-gray-400 hover:bg-white/[0.12] hover:text-blue-600'
            }`}
            onClick={() => setActiveTab('insights')}
          >
            AI Insights
          </button>
          <button
            className={`w-full py-2.5 text-sm font-medium leading-5 rounded-lg transition-colors ${
              activeTab === 'assistant'
                ? 'bg-white dark:bg-gray-800 shadow text-blue-700 dark:text-blue-400'
                : 'text-gray-600 dark:text-gray-400 hover:bg-white/[0.12] hover:text-blue-600'
            }`}
            onClick={() => setActiveTab('assistant')}
          >
            Assistant
          </button>
        </div>

        {activeTab === 'insights' ? (
          <div className="space-y-6">

            {/* Queue Overview */}
            <div className="space-y-4">
              <div 
                className="flex items-center justify-between cursor-pointer" 
                onClick={onQueueClick} 
              >
                <h3 className="text-lg font-semibold">Energization queue overview</h3>
                <ChevronRight className="h-5 w-5" />
              </div>
              
              {/* <div className="grid gap-4">
                <div className="flex items-center space-x-4 p-4 rounded-lg bg-gray-50 dark:bg-white-800">
                  <div className="p-2 rounded-full bg-blue-100 dark:bg-blue-900">
                    <BatteryCharging className="h-6 w-6 text-blue-600 dark:text-blue-400" />
                  </div>
                  <div>
                    <div className="text-2xl font-bold text-gray-600 dark:text-black">4000</div>
                    <div className="text-sm text-gray-600 dark:text-black">kW</div>
                    <div className="text-xs text-gray-500 dark:text-gray-600">Peak Load</div>
                  </div>
                </div>
                <div className="flex items-center space-x-4 p-4 rounded-lg bg-gray-50 dark:bg-white-800">
                  <div className="p-2 rounded-full bg-blue-100 dark:bg-blue-900">
                    <Users className="h-6 w-6 text-blue-600 dark:text-blue-400" />
                  </div>
                  <div>
                    <div className="text-2xl font-bold text-gray-600 dark:text-black">450</div>
                    <div className="text-sm text-gray-600 dark:text-gray-600">EV chargers</div>
                  </div>
                </div>
              </div> */}
            </div>

            {/* Scenario Selection */}
            <div className="mb-6">
              <CustomDropdown
                options={scenarios}
                value={selectedScenario}
                onChange={onScenarioChange}
                darkMode={darkMode}
              />
            </div>

            {/* Analysis Results */}
            <div className="space-y-4">
              <div 
                className="flex items-center justify-between cursor-pointer"
                onClick={onExpand}
              >
                <h3 className="text-lg font-semibold">8760 analysis results</h3>
                <ChevronRight className="h-5 w-5" />
              </div>

              <div className="space-y-4">
                <div className="p-4 rounded-lg bg-gray-50 dark:bg-white-800">
                  <div className="flex justify-between items-start">
                    <div className="flex items-start space-x-3">
                      <div className="p-2 rounded-full bg-blue-100 dark:bg-blue-900">
                        <AlertTriangle className="h-5 w-5 text-blue-600 dark:text-blue-400" />
                      </div>
                      <div>
                        <div className="text-sm text-gray-600 dark:text-gray-600">Total Violations</div>
                        <div className="text-2xl font-bold text-gray-600 dark:text-black">{analysisValues.totalViolationsCount}</div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 grid grid-cols-2 gap-4">
                    <div>
                      <div className="text-sm text-gray-600 dark:text-gray-600">Under voltage</div>
                      <div className="flex items-center">
                        <div className="text-xl font-semibold text-gray-600 dark:text-black">{analysisValues.underVoltageCount}</div>
                      </div>
                    </div>
                    <div>
                      <div className="text-sm text-gray-600 dark:text-gray-600">Over voltage</div>
                      <div className="flex items-center">
                        <div className="text-xl font-semibold text-gray-600 dark:text-black">{analysisValues.overVoltageCount}</div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <StatCard
                  icon={Clock}
                  label="Average violation duration"
                  value={analysisValues.averageViolationDuration}
                /> */}

                <div className="grid grid-cols-2 gap-4">
                  <StatCard
                    label="Constraints on assets"
                    value={analysisValues.assetConstraintsCount}
                  />
                  <StatCard
                    label="Congestion on lines"
                    value={analysisValues.linesCongestionCount}
                  />
                </div>
              </div>
            </div>

            {/* Recommendations Section */}
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-semibold">Solution Recommendations</h3>
                <div className="relative group">
                  <Info className="h-5 w-5 text-blue-600 dark:text-blue-400" />
                  <div className="absolute invisible group-hover:visible bg-white text-gray-500 text-sm py-2 px-3 rounded-md -right-2 top-7 whitespace-nowrap z-50 shadow-lg transform translate-y-2">
                    Coming Soon...
                    <div className="absolute -top-1 right-3 h-2 w-2 bg-white transform rotate-45"></div>
                  </div>
                </div>
              </div>

              <div className="flex items-center space-x-2">
                  <span className="text-sm text-gray-500 dark:text-gray-500">Sort by</span>
                  <select 
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    className={`text-sm rounded-md border ${
                      darkMode 
                        ? 'bg-gray-800 border-gray-700 text-gray-300' 
                        : 'bg-white border-gray-200 text-black'
                    } p-1`}
                  >
                    <option value="cost">Cost optimized</option>
                    <option value="sustainability">Sustainability</option>
                    <option value="time">Implementation time</option>
                  </select>
                </div>

              <RecommendationCard
                icon={Zap}
                title="Demand Flexibility"
                subtitle="50% flexibility at energization node"
                // powerValue="1900"
                // feedersValue="45"
                darkMode={darkMode}
              />

              <RecommendationCard
                icon={GitBranch}
                title="Topology Optimization"
                subtitle="Switch optimization"
                darkMode={darkMode}
              />

              <RecommendationCard
                icon={Database}
                title="Energy Storage"
                subtitle="Battery storage systems"
                darkMode={darkMode}
              />
            </div>

          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-[calc(100%-4rem)] space-y-4">
          <p className={`text-lg ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
            AI Assistant functionality coming soon...
          </p>
          <button
            onClick={onPreviewClick}
            className={`px-4 py-2 rounded-lg transition-colors ${
            darkMode 
            ? 'bg-blue-600 hover:bg-blue-700 text-white' 
            : 'bg-blue-500 hover:bg-blue-600 text-white'
            }`}
          >
        Preview Features
      </button>
    </div>
        )}
      </div>
    </div>
  );
}

export default AISummary;