import React from 'react';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const ImageViewerModal = ({ 
  isOpen, 
  onClose, 
  currentImage, 
  onNext, 
  onPrevious, 
  totalImages, 
  currentIndex,
  darkMode 
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-[3000]"
          onClick={onClose}
        >
          {/* Close button */}
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-white p-2 rounded-full hover:bg-white/10 transition-colors"
          >
            <X size={24} />
          </button>

          {/* Navigation buttons */}
          {currentIndex > 0 && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                onPrevious();
              }}
              className="absolute left-4 text-white p-2 rounded-full hover:bg-white/10 transition-colors"
            >
              <ChevronLeft size={36} />
            </button>
          )}
          
          {currentIndex < totalImages - 1 && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                onNext();
              }}
              className="absolute right-4 text-white p-2 rounded-full hover:bg-white/10 transition-colors"
            >
              <ChevronRight size={36} />
            </button>
          )}

          {/* Image container */}
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="relative max-w-[90vw] max-h-[90vh]"
            onClick={(e) => e.stopPropagation()}
          >
            <img
              src={currentImage?.src}
              alt={currentImage?.alt}
              className="max-w-full max-h-[80vh] object-contain"
            />
            
            {/* Caption */}
            <div className="absolute bottom-0 left-0 right-0 text-center p-4 bg-black/50 text-white">
              <p className="text-lg">{currentImage?.alt}</p>
              <p className="text-sm text-gray-300 mt-1">{currentImage?.caption}</p>
              <p className="text-sm text-gray-400 mt-2">
                Image {currentIndex + 1} of {totalImages}
              </p>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ImageViewerModal;