import React, { useState, useEffect, useRef, useCallback } from 'react';
import { MapContainer, GeoJSON, TileLayer, Marker, Polyline, ZoomControl, LayersControl, useMapEvents} from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import RightPanel from './util/RightPanel/rightPanel';
import LoadingOverlay from './util/LoadingOverlay/loadingOverlay';
import Dashboard from './dashboard';
import EnergizationQueueModal from './components/energizationQueueModal';
import AssistantPreviewModal from './components/assistantPreviewModal';
import AISummary from './components/aiSummary';
import { X, ChevronDown, ChevronRight } from 'lucide-react';
import MapSearch from './components/mapSearch';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import L from 'leaflet';

const customMarkerIcons = {
  node: L.icon({
    iconUrl: '/icons/node.png',
    iconSize: [24, 24],
    iconAnchor: [12, 12],
  }),
  transformer: L.icon({
    iconUrl: '/icons/tf.png',
    iconSize: [24, 24],
    iconAnchor: [12, 12],
  }),
  load: L.icon({
    iconUrl: '/icons/load.png',
    iconSize: [24, 24],
    iconAnchor: [12, 12],
  }),
  violatedNode: L.icon({
    iconUrl: '/icons/violated-node.png',
    iconSize: [24, 24],
    iconAnchor: [12, 12],
  }),
  violatedTransformer: L.icon({
    iconUrl: '/icons/violated-tf.png',
    iconSize: [24, 24],
    iconAnchor: [12, 12],
  }),
  violatedLoad: L.icon({
    iconUrl: '/icons/violated-load.png',
    iconSize: [24, 24],
    iconAnchor: [12, 12],
  }),
  default: L.icon({
    iconUrl: '/icons/default.png',
    iconSize: [24, 24],
    iconAnchor: [12, 12],
  }),
  violatedDefault: L.icon({
    iconUrl: '/icons/violated-default.png',
    iconSize: [24, 24],
    iconAnchor: [12, 12],
  }),
};

const Legend = ({ darkMode }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`absolute top-1 left-[275px] z-[900] ${
      darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'
    } rounded-lg shadow-lg`}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-between w-full px-4 py-2 font-medium"
      >
        <span>Legend</span>
        {isOpen ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
      </button>
      
      {isOpen && (
        <div className="px-4 pb-4 pt-2">
          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <img src="/icons/node.png" alt="Node" className="w-5 h-5" />
              <span>Node</span>
            </div>
            <div className="flex items-center space-x-2">
              <img src="/icons/tf.png" alt="Transformer" className="w-5 h-5" />
              <span>Transformer</span>
            </div>
            <div className="flex items-center space-x-2">
              <img src="/icons/load.png" alt="Load" className="w-5 h-5" />
              <span>Load</span>
            </div>
            <div className="flex items-center space-x-2">
              <img src="/icons/violated-node.png" alt="Violated Node" className="w-5 h-5" />
              <span>Violated Node</span>
            </div>
            <div className="flex items-center space-x-2">
              <img src="/icons/violated-load.png" alt="Violated Load" className="w-5 h-5" />
              <span>Violated Load</span>
            </div>
            <div className="flex items-center space-x-2">
              <img src="/icons/violated-tf.png" alt="Violated Transformer" className="w-5 h-5" />
              <span>Violated Transformer</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

  const DashboardModal = ({ isOpen, onClose, darkMode, selectedScenario }) => {
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 z-[1000] overflow-y-auto">
        {/* Backdrop */}
        <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose}></div>
        
        {/* Modal */}
        <div className={`relative w-full h-full ${darkMode ? 'bg-gray-900' : 'bg-white'}`}>
          {/* Header */}
          <div className={`flex items-center justify-between p-4 border-b ${
            darkMode ? 'border-gray-700' : 'border-gray-200'
          }`}>
            <h2 className={`text-xl font-semibold ${darkMode ? 'text-gray-200' : 'text-gray-900'}`}>
              Inspect Analysis Results
            </h2>
            <button
              onClick={onClose}
              className={`p-2 rounded-lg transition-colors ${
                darkMode 
                  ? 'hover:bg-gray-800 text-gray-400 hover:text-gray-200' 
                  : 'hover:bg-gray-100 text-gray-600 hover:text-gray-900'
              }`}
            >
              <X size={24} />
            </button>
          </div>
          
          {/* Dashboard Content */}
          <div className="h-[calc(100vh-4rem)] overflow-y-auto">
            <Dashboard darkMode={darkMode} selectedScenario={selectedScenario} />
          </div>
        </div>
      </div>
    );
  };

const ModifiedGeoJsonViewer = ({ geoJsonFiles, darkMode, selectedScenario, onScenarioChange }) => {
  const [, setGeoJsonData] = useState([]);
  const [isCluster, setIsCluster] = useState(true);
  const [,setSelectedGeoJson] = useState(null);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [panelData, setPanelData] = useState(null);
  const [currentZoom, setCurrentZoom] = useState(14);
  const [mapBounds, setMapBounds] = useState(null);
  const [visibleData, setVisibleData] = useState([]);
  const [detailTimestamp, setDetailTimestamp] = useState(null);
  let [violatedAssets, setViolatedAssets] = useState([]);
  let [violatedLines, setViolatedLines] = useState([]);
  let [violatedTransformers, setViolatedTransformers] = useState([]);
  const [assetDetails, setAssetDetails] = useState(null);
  const [lineDetails, setLineDetails] = useState(null);
  const [transformerDetails, setTransformerDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [, setWorstPerformingHour] = useState('');
  const [, setTotalAssets] = useState(0);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isDashboardOpen, setIsDashboardOpen] = useState(false);
  const [isQueueModalOpen, setIsQueueModalOpen] = useState(false);
  const [isAssistantPreviewOpen, setIsAssistantPreviewOpen] = useState(false);
  const [analysisData, setAnalysisData] = useState(null);
  const [error, setError] = useState(null);
  const mapRef = useRef(null);

  const ZOOM_THRESHOLD = 20; // Set the zoom level at which clustering should be disabled


  const fetchWithProgress = async (url, progressStart, progressEnd) => {
    const response = await fetch(url);
    const contentLength = +response.headers.get('Content-Length');
    const reader = response.body.getReader();
    let receivedLength = 0;
    let chunks = [];
  
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      chunks.push(value);
      receivedLength += value.length;
      
      // Calculate progress with validation
      const progressRange = progressEnd - progressStart;
      const progressIncrement = contentLength 
        ? (progressRange * (receivedLength / contentLength))
        : 0;
      const currentProgress = progressStart + progressIncrement;
      
      // Ensure progress is always valid
      setLoadingProgress(Math.min(Math.max(0, Math.round(currentProgress)), 100));
    }
  
    let chunksAll = new Uint8Array(receivedLength);
    let position = 0;
    for (let chunk of chunks) {
      chunksAll.set(chunk, position);
      position += chunk.length;
    }
  
    return JSON.parse(new TextDecoder('utf-8').decode(chunksAll));
  };

  const closePanel = useCallback(() => {
    setIsPanelOpen(false);
    setSelectedFeature(null);
  }, []);

  const loadData = useCallback(async () => {
    setIsLoading(true);
    closePanel();
    setLoadingProgress(0);
    setError(null);

    try {
      // Check cache for GeoJSON data
      const cachedData = localStorage.getItem('geoJsonData');
      let data;
      if (cachedData) {
        data = JSON.parse(cachedData);
        setLoadingProgress(25);
      } else {
        // Load GeoJSON data
        data = await Promise.all(
          geoJsonFiles.map(async (file, index) => {
            const json = await fetchWithProgress(file, index * (25 / geoJsonFiles.length), (index + 1) * (25 / geoJsonFiles.length));
            return json;
          })
        );
        localStorage.setItem('geoJsonData', JSON.stringify(data));
      }
      setGeoJsonData(data);
      setFilteredData(data);

      // Analysis data fetch
      let analysisResponse = 'https://stg.ext.thinklabs.ai/backend/api/v1/dashboard/analysis';
      if (selectedScenario === 'base') {
        analysisResponse += '?scenario=base';
      } else if (selectedScenario === 'er4109') {
        analysisResponse += '?scenario=er050-4109';
      } else if (selectedScenario === 'er4109_1') {
        analysisResponse += '?scenario=er025-4109';
      } else if (selectedScenario === 'er4139') {
        analysisResponse += '?scenario=er075-4139';
      } else if (selectedScenario === 'er7633') {
        analysisResponse += '?scenario=er075-7543';
      }
      const analysisData = await fetchWithProgress(analysisResponse, 25, 30);
      setAnalysisData(analysisData);

      // Fetch violated assets
      let endpoint = 'https://stg.ext.thinklabs.ai/backend/api/v1/network-viewer/max-violations';
      console.log(selectedScenario);
      if (selectedScenario === 'base') {
        endpoint += '?scenario=base';
      } else if (selectedScenario === 'er4109') {
        endpoint += '?scenario=er050-4109';
      } else if (selectedScenario === 'er4109_1') {
        endpoint += '?scenario=er025-4109';
      } else if (selectedScenario === 'er4139') {
        endpoint += '?scenario=er075-4139';
      } else if (selectedScenario === 'er7633') {
        endpoint += '?scenario=er075-7543';
      }
      const violatedAssetRes = await fetchWithProgress(endpoint, 30, 65);
      const violatedAssetsList = violatedAssetRes.busViolations.map(asset => asset.bus_name);
      setViolatedAssets(violatedAssetsList);

      const violatedLinesList = violatedAssetRes.lineViolations.map(asset => asset.name);
      setViolatedLines(violatedLinesList);

      const violatedTransformersList = violatedAssetRes.transformerViolations.map(asset => asset.name);
      setViolatedTransformers(violatedTransformersList);

      // Fetch asset details
      let detailsEndpoint = 'https://stg.ext.thinklabs.ai/backend/api/v1/network-viewer/max-violations/asset-details';
      if (selectedScenario === 'base') {
        detailsEndpoint += '?scenario=base';
      } else if (selectedScenario === 'er4109') {
        detailsEndpoint += '?scenario=er050-4109';
      } else if (selectedScenario === 'er4109_1') {
        detailsEndpoint += '?scenario=er025-4109';
      } else if (selectedScenario === 'er4139') {
        detailsEndpoint += '?scenario=er075-4139';
      } else if (selectedScenario === 'er7633') {
        detailsEndpoint += '?scenario=er075-7543';
      }
      const detailsData = await fetchWithProgress(detailsEndpoint, 65, 100);
      setAssetDetails(detailsData.busDetails);
      setDetailTimestamp(detailsData.timestamp);
      setLineDetails(detailsData.lineDetails);
      setTransformerDetails(detailsData.transformerDetails);

      setWorstPerformingHour(detailsData.timestamp || 'N/A');
      setTotalAssets(detailsData.busDetails.length || 0);
      setVisibleData([]);
      setIsLoading(false);
    } catch (error) {
      console.error('Error loading data:', error);
      setError('Failed to load data. Please check your connection and try again.');
      setIsLoading(false);
    }
  }, [closePanel, selectedScenario, geoJsonFiles]);

  useEffect(() => {
    loadData();
  }, [geoJsonFiles, selectedScenario, loadData]);

  const handleRetry = () => {
    loadData();
  };

  useEffect(() => {
    if (mapBounds && currentZoom >= ZOOM_THRESHOLD) {
      const visible = filteredData.filter(data => {
        if (data.type === 'FeatureCollection') {
          return data.features.some(feature => isFeatureVisible(feature, mapBounds));
        } else if (data.type === 'Feature') {
          return isFeatureVisible(data, mapBounds);
        }
        return false;
      });
      setVisibleData(visible);
    } else {
      setVisibleData([]);
    }
  }, [filteredData, mapBounds, currentZoom]);

  const isFeatureVisible = (feature, bounds) => {
    if (feature.geometry.type === 'Point') {
      const [lon, lat] = feature.geometry.coordinates;
      return bounds.contains([lat, lon]);
    } else if (feature.geometry.type === 'LineString') {
      return feature.geometry.coordinates.some(([lon, lat]) => bounds.contains([lat, lon]));
    }
    return false;
  };

  const MapEvents = () => {
    const map = useMapEvents({
      click: (e) => {
        if (e.originalEvent.target.classList.contains('leaflet-container')) {
          closePanel();
        }
      },
      moveend: () => {
        setMapBounds(map.getBounds());
      },
      zoomend: () => {
        setCurrentZoom(map.getZoom());
        setMapBounds(map.getBounds());
      },
    });
    return null;
  };

  const handleBackToCluster = () => {
    setSelectedGeoJson(null);
    setIsCluster(true);
    closePanel();
  };

  const onEachFeature = (feature, layer) => {
    if (feature.properties) {
      layer.on('click', (e) => {
        L.DomEvent.stopPropagation(e);
        setSelectedFeature(feature);
        let assetId;
        if(feature.properties.type === 'Transformer') {
          assetId = feature.properties.name;
          const assetDetail = transformerDetails?.find(asset => asset.line_name.startsWith(assetId));
          setPanelData({
            title: feature.properties.type,
            data: {
              ...feature.properties,
              timestamp: detailTimestamp,
              assetDetail: assetDetail
            }
          });
          setIsPanelOpen(true);
        } else {
          if(feature.properties.type === 'Load') {
            assetId = feature.properties.name.split('_')[1];
          } else {
            assetId = feature.properties.name;
          }
          const assetDetail = assetDetails?.find(asset => asset.bus_name.startsWith(assetId));
          setPanelData({
            title: feature.properties.type,
            data: {
              ...feature.properties,
              timestamp: detailTimestamp,
              assetDetail: assetDetail
            }
          });
          setIsPanelOpen(true);
        }
      });
  
      if(feature.geometry.type === 'LineString') {
        layer.on('click', (e) => {
          L.DomEvent.stopPropagation(e);
          setSelectedFeature(feature);
          const assetDetail = lineDetails?.find(asset => asset.line_name.startsWith(feature.properties.name));
          setPanelData({
            title: feature.properties.type,
            data: {
              ...feature.properties,
              timestamp: detailTimestamp,
              assetDetail: assetDetail
            }
          });
          setIsPanelOpen(true);
        });
  
        // Update mouseover/mouseout handlers
        layer.on('mouseover', () => {
          const isViolated = violatedLines.includes(feature.properties.name);
          layer.setStyle({
            weight: 5,
            color: isViolated ? '#ff6b6b' : (darkMode ? '#90cdf4' : '#3182ce'),
            opacity: 0.9
          });
        });
  
        layer.on('mouseout', () => {
          if (selectedFeature && selectedFeature.properties.name === feature.properties.name) {
            // If this is the selected feature, maintain the selection style
            const isViolated = violatedLines.includes(feature.properties.name);
            layer.setStyle({
              weight: 5,
              color: isViolated ? '#ff6b6b' : (darkMode ? '#90cdf4' : '#3182ce'),
              opacity: 0.9
            });
          } else {
            // Reset to default style
            const isViolated = violatedLines.includes(feature.properties.name);
            layer.setStyle({
              weight: 3,
              color: isViolated ? '#e53e3e' : '#2f855a',
              opacity: 0.9
            });
          }
        });
      }
    }
  };

  const createHaloOverlay = (feature) => {
    if (!selectedFeature || feature.properties.name !== selectedFeature.properties.name) {
      return null;
    }

    if (feature.geometry?.type === 'LineString') {
      const { coordinates } = feature.geometry;
      const latlngs = coordinates.map(([lon, lat]) => [lat, lon]);
      return (
        <Polyline
          positions={latlngs}
          pathOptions={{ color: darkMode ? '#faf089' : '#ecc94b', weight: 15, opacity: 0.5 }}
        />
      );
    }
    if (feature.geometry?.type === 'Point') {
      const { coordinates } = feature.geometry;
      return (
        <Marker
          position={[coordinates[1], coordinates[0]]}
          icon={L.icon({
            iconUrl: '/icons/halo.png',
            iconSize: [40, 40],
            iconAnchor: [20, 20],
          })}
        />
      );
    }
    return null;
  };

  const createClusterCustomIcon = (cluster) => {
    const childCount = cluster.getChildCount();
    const violatedCount = cluster.getAllChildMarkers().filter(marker => 
      (violatedAssets.includes(marker.feature?.properties?.name) || violatedTransformers.includes(marker.feature?.properties?.name)) && isCluster
    ).length;

    let className = 'marker-cluster-custom';
    let count = childCount;

    if (violatedCount > 0) {
      className += ' marker-cluster-violated';
      count = violatedCount;
    }

    return L.divIcon({
      html: `<div><span>${count}</span></div>`,
      className: className,
      iconSize: L.point(40, 40)
    });
  };
  const pointToLayer = (feature, latlng) => {
    if (feature.geometry.type === 'Point') {
      let isViolated;
      if(feature.properties.type === 'Node') {
        isViolated = violatedAssets.includes(feature.properties.name);
      }
      else if(feature.properties.type === 'Load') {
        isViolated = violatedAssets.includes(feature.properties.name.split('_')[1]);
      } else {
        isViolated = violatedTransformers.includes(feature.properties.name);
      }
      let icon;

      if (isViolated) {
        switch (feature.properties.type) {
          case 'Node':
            icon = customMarkerIcons.violatedNode;
            break;
          case 'Transformer':
            icon = customMarkerIcons.violatedTransformer;
            break;
          case 'Load':
            icon = customMarkerIcons.violatedLoad;
            break;
          default:
            icon = customMarkerIcons.violatedDefault;
        }
      } else {
        switch (feature.properties.type) {
          case 'Node':
            icon = customMarkerIcons.node;
            break;
          case 'Transformer':
            icon = customMarkerIcons.transformer;
            break;
          case 'Load':
            icon = customMarkerIcons.load;
            break;
          default:
            icon = customMarkerIcons.default;
        }
      }

      const markerOptions = { 
        icon,
        className: isViolated ? 'violated-marker' : ''
      };

      return L.marker(latlng, markerOptions);
    }
  };

  const getLineStyle = (feature) => {
    const isViolated = violatedLines.includes(feature.properties.name);
    const isSelected = selectedFeature && selectedFeature.properties.name === feature.properties.name;
    
    // Base color based on violation status
    const baseColor = isViolated ? '#e53e3e' : '#2f855a';
    
    // If selected, modify the style while preserving the violation color
    if (isSelected) {
      return {
        color: isViolated ? '#ff6b6b' : (darkMode ? '#90cdf4' : '#3182ce'), // Brighter red if violated, else selection color
        weight: 5,
        opacity: 0.9,
        fillOpacity: 0.7
      };
    }
    
    // Default style
    return {
      color: baseColor,
      fillColor: baseColor,
      weight: 3,
      fillOpacity: 0.5
    };
  };

  const renderGeoJsonLayer = (data, index) => {
    if (data.type === 'FeatureCollection') {
      return (
        <React.Fragment key={`collection-${index}`}>
          <GeoJSON
            data={data}
            onEachFeature={onEachFeature}
            pointToLayer={pointToLayer}
            style={getLineStyle}
          />
          {data.features.map((feature, featureIndex) => (
            <React.Fragment key={`halo-${index}-${featureIndex}`}>
              {createHaloOverlay(feature)}
            </React.Fragment>
          ))}
        </React.Fragment>
      );
    } else if (data.type === 'Feature') {
      if (data.geometry.type === 'Point') {
        let iconUrl = '';
        if (data.properties.type === 'Transformer') {
          iconUrl = customMarkerIcons.transformer.options.iconUrl;
        } else if (data.properties.type === 'Node') {
          iconUrl = customMarkerIcons.node.options.iconUrl;
        } else if (data.properties.type === 'Load') {
          iconUrl = customMarkerIcons.load.options.iconUrl;
        } else {
          iconUrl = customMarkerIcons.default.options.iconUrl;
        }
        return (
          <React.Fragment key={`feature-${data.properties.name}-${index}`}>
            {createHaloOverlay(data)}
            <Marker
              position={[data.geometry.coordinates[1], data.geometry.coordinates[0]]}
              icon={L.icon({
                iconUrl: iconUrl,
                iconSize: [24, 24],
                iconAnchor: [12, 12],
              })}
              eventHandlers={{
                click: (e) => {
                  L.DomEvent.stopPropagation(e);
                  setSelectedFeature(data);
                  setPanelData({
                    title: data.properties.type,
                    data: data.properties
                  });
                  setIsPanelOpen(true);
                }
              }}
            />
          </React.Fragment>
        );
      }
    }
    return null;
  };

  const handleSearchSelect = (suggestion) => {
    const { feature, center } = suggestion;
    
    // Set selected feature
    setSelectedFeature(feature);
    
    // Zoom to the selected feature
    if (mapRef.current) {
      const map = mapRef.current;
      
      // If we're in cluster view and zoom level is below threshold, zoom in
      if (currentZoom < ZOOM_THRESHOLD) {
        // For lines, use the center point calculated in MapSearch
        map.setView([center[1], center[0]], ZOOM_THRESHOLD);
      } else {
        map.setView([center[1], center[0]], currentZoom);
      }
    }
  
    // Setup panel data based on feature type
    if (feature.geometry.type === 'LineString') {
      const assetDetail = lineDetails?.find(asset => asset.line_name.startsWith(feature.properties.name));
      setPanelData({
        title: feature.properties.type,
        data: {
          ...feature.properties,
          timestamp: detailTimestamp,
          assetDetail: assetDetail
        }
      });
    } else {
      let assetId;
      if (feature.properties.type === 'Transformer') {
        assetId = feature.properties.name;
        const assetDetail = transformerDetails?.find(asset => asset.line_name.startsWith(assetId));
        setPanelData({
          title: feature.properties.type,
          data: {
            ...feature.properties,
            timestamp: detailTimestamp,
            assetDetail: assetDetail
          }
        });
      } else {
        if (feature.properties.type === 'Load') {
          assetId = feature.properties.name.split('_')[1];
        } else {
          assetId = feature.properties.name;
        }
        const assetDetail = assetDetails?.find(asset => asset.bus_name.startsWith(assetId));
        setPanelData({
          title: feature.properties.type,
          data: {
            ...feature.properties,
            timestamp: detailTimestamp,
            assetDetail: assetDetail
          }
        });
      }
    }
    
    setIsPanelOpen(true);
  };

  return (
    <div className={`relative h-[calc(100vh-3.5rem)] ${darkMode ? 'bg-gray-900' : 'bg-gray-100'} overflow-x-hidden`}>
      {isLoading || error ? (
        <LoadingOverlay
          darkMode={darkMode}
          progress={loadingProgress}
          error={error}
          onRetry={handleRetry}
        />
      ) : (
        <>
          {/* Left Sidebar with AI Summary - now w-80 (320px) */}
          <div className="absolute left-0 top-0 bottom-0 w-80 z-10 border-r overflow-y-auto overflow-x-hidden">
          <AISummary 
            darkMode={darkMode}
            selectedScenario={selectedScenario}
            onScenarioChange={onScenarioChange}
            onExpand={() => setIsDashboardOpen(true)}
            onQueueClick={() => setIsQueueModalOpen(true)}
            onPreviewClick={() => setIsAssistantPreviewOpen(true)}
            analysisData={analysisData}
            isLoading={isLoading}
          />
          </div>

          {/* Map Search - adjusted position */}
          <div className="absolute left-80 right-0 top-0 h-12 flex items-center pl-4 pr-4 space-x-4">
            <div className="w-64">
              <MapSearch 
                darkMode={darkMode}
                geoJsonData={filteredData}
                onSelect={handleSearchSelect}
              />
            </div>
            <Legend darkMode={darkMode} />
          </div>

          {/* Map container - adjusted left position */}
          <div className="absolute left-80 right-0 top-0 bottom-0">
            <MapContainer
              ref={mapRef}
              center={[37.75193, -122.39246]}
              zoom={16}
              className="w-full h-full"
              zoomControl={false}
              maxZoom={30}
            >
              <MapEvents />
              <LayersControl position="bottomright">
                <LayersControl.BaseLayer checked={darkMode} name="Dark">
                  <TileLayer
                    url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                    maxZoom={30}
                    maxNativeZoom={20}
                  />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer checked={!darkMode} name="Light">
                  <TileLayer
                    url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                    maxZoom={30}
                    maxNativeZoom={20}
                  />
                </LayersControl.BaseLayer>
              </LayersControl>
              <ZoomControl position="bottomleft" />
              
              <MarkerClusterGroup
                chunkedLoading
                spiderfyOnMaxZoom={false}
                disableClusteringAtZoom={ZOOM_THRESHOLD}
                iconCreateFunction={createClusterCustomIcon}
              >
                {filteredData.map((data, index) => 
                  currentZoom < ZOOM_THRESHOLD || !visibleData.includes(data) 
                    ? renderGeoJsonLayer(data, index) 
                    : null
                )}
              </MarkerClusterGroup>
              {currentZoom >= ZOOM_THRESHOLD && visibleData.map((data, index) => renderGeoJsonLayer(data, index))}
            </MapContainer>
          </div>

          {/* Rest of the components remain the same */}
          <DashboardModal 
            isOpen={isDashboardOpen}
            onClose={() => setIsDashboardOpen(false)}
            darkMode={darkMode}
            selectedScenario={selectedScenario}
          />

          <EnergizationQueueModal 
            isOpen={isQueueModalOpen}
            onClose={() => setIsQueueModalOpen(false)}
            darkMode={darkMode}
          />

          <AssistantPreviewModal 
            isOpen={isAssistantPreviewOpen}
            onClose={() => setIsAssistantPreviewOpen(false)}
            darkMode={darkMode}
          />

{!isCluster && (
            <div className="absolute left-80 right-0 bottom-4 flex justify-center items-center z-[1000]">
              <button
                className={`${darkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white px-4 py-2 rounded-md shadow-md transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50`}
                onClick={handleBackToCluster}
              >
                Back to Cluster View
              </button>
            </div>
          )}

          {/* Right Panel - no changes needed */}
          <RightPanel
            isOpen={isPanelOpen}
            onClose={closePanel}
            panelData={panelData}
            darkMode={darkMode}
            selectedScenario={selectedScenario}
          />
        </>
      )}
    </div>
  );
};

export default ModifiedGeoJsonViewer;