import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { Moon, Sun, ChevronDown, BarChart2, Timer, Target } from 'lucide-react';
import { 
    NotificationButton, 
    NotificationPanel, 
    ToastNotification 
} from '../../components/notifications';
import PowerFlowModal from '../../components/powerFlowModal';

export default function ModifiedHeader({ 
  darkMode, 
  setDarkMode, 
  user, 
  onSignOut,
  toastNotification,
  setToastNotification,
  notifications,
  unreadCount,
  markAllAsRead,
  clearNotifications
}) {
    const [isNotificationPanelOpen, setIsNotificationPanelOpen] = useState(false);
    const [isPowerFlowModalOpen, setIsPowerFlowModalOpen] = useState(false); // Set to true by default
    const navigate = useNavigate();

    // Close modal after first view
    useEffect(() => {
        const hasSeenModal = localStorage.getItem('hasSeenPowerFlowModal');
        const isChangingPassword = window.location.pathname === '/change-password';
        if (!hasSeenModal && user && !isChangingPassword) {
          setIsPowerFlowModalOpen(true);
          localStorage.setItem('hasSeenPowerFlowModal', 'true');
        } else {
          setIsPowerFlowModalOpen(false);
        }
      }, [user]);

    const handleNotificationClick = () => {
        setIsNotificationPanelOpen(!isNotificationPanelOpen);
        if (!isNotificationPanelOpen) {
          markAllAsRead();
        }
    };

    const toggleDarkMode = useCallback((e) => {
        e.preventDefault(); // Prevent form submission
        e.stopPropagation(); // Prevent event bubbling
        setDarkMode(prevMode => {
          const newMode = !prevMode;
          localStorage.setItem('darkMode', JSON.stringify(newMode));
          return newMode;
        });
    }, [setDarkMode]);

    const handleSignOut = () => {
        onSignOut();
        navigate('/signin');
    };

    return (
        <div className={`h-14 flex items-center px-4 fixed w-full space-x-4 top-0 z-[1000] ${darkMode ? 'bg-gray-900' : 'bg-white'} border-b`}>
            {toastNotification && (
                <ToastNotification
                    message={toastNotification}
                    onClose={() => setToastNotification(null)}
                    darkMode={darkMode}
                />
            )}
            {/* Logo */}
            <div className="flex items-center">
                <button href="" className="mr-6">
                    <img
                        src={darkMode ? 'https://www.thinklabs.ai/images/logo.png' : '/icons/logo-light.jpg'}
                        alt="Thinklabs Logo"
                        className="h-6 w-auto"
                    />
                </button>
                <h1 className={`text-lg font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                    DYNAMIC PLANNING
                </h1>
            </div>

            {/* Spacer */}
            <div className="flex-1"></div>

            {/* Combined PowerFlow Stats Button */}
            {user && (
              <button
                onClick={() => setIsPowerFlowModalOpen(true)}
                className={`flex items-center space-x-3 px-4 py-2 rounded-lg ml-4 transition-all duration-200 ${
                  darkMode 
                    ? 'bg-gray-800 hover:bg-gray-700 text-gray-200' 
                    : 'bg-blue-50 hover:bg-blue-100 text-gray-700'
                } hover:shadow-md`}
              >
                {/* Accuracy Section */}
                <div className="flex items-center space-x-2">
                  <span className={darkMode ? 'text-gray-300' : 'text-gray-600'}>
                    Accuracy
                  </span>
                  <Target className={`w-4 h-4 ${
                    darkMode ? 'text-green-400' : 'text-green-500'
                  }`} />
                  <span className={`font-medium ${
                    darkMode ? 'text-green-400' : 'text-green-600'
                  }`}>
                    99.5%
                  </span>
                </div>

                {/* Divider */}
                <span className={`h-4 w-px ${
                  darkMode ? 'bg-gray-700' : 'bg-gray-300'
                }`} />

                {/* PowerFlow Section */}
                <div className="flex items-center space-x-2">
                  <BarChart2 className={`w-4 h-4 ${
                    darkMode ? 'text-blue-400' : 'text-blue-500'
                  }`} />
                  <span className={darkMode ? 'text-gray-300' : 'text-gray-600'}>
                    8760 PowerFlow
                  </span>
                  <Timer className={`w-4 h-4 ${
                    darkMode ? 'text-blue-400' : 'text-blue-500'
                  }`} />
                  <span className={darkMode ? 'text-gray-400' : 'text-gray-500'}>
                    ~5 mins
                  </span>
                </div>
              </button>
            )}

            {/* Right section with actions */}
            {user && (
                <div className="flex items-center space-x-4">
                    <div className="relative">
                        {/* <NotificationButton
                            onClick={handleNotificationClick}
                            darkMode={darkMode}
                            count={unreadCount}
                        /> */}
                        
                        <NotificationPanel
                            isOpen={isNotificationPanelOpen}
                            notifications={notifications}
                            onClose={() => setIsNotificationPanelOpen(false)}
                            onClear={clearNotifications}
                            darkMode={darkMode}
                        />
                    </div>

                    <Menu as="div" className="relative ml-3">
                        <Menu.Button className="flex items-center">
                            <img
                                className="h-8 w-8 rounded-full"
                                src={`https://ui-avatars.com/api/?name=${user.email.substring(0, 1)}&background=random`}
                                alt=""
                            />
                            <ChevronDown className="ml-1 h-4 w-4 text-gray-400" aria-hidden="true" />
                        </Menu.Button>

                        <Transition
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className={`absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5 focus:outline-none ${
                                darkMode ? 'bg-gray-800' : 'bg-white'
                            }`}>
                                <Menu.Item>
                                    {({ active }) => (
                                        <div className={`px-4 py-2 text-sm ${darkMode ? 'text-gray-200' : 'text-gray-700'}`}>
                                            <div className="flex items-center justify-between">
                                                <div className="flex items-center space-x-2">
                                                    <Sun className="h-4 w-4" />
                                                    <span>Light</span>
                                                    <button
                                                        onClick={toggleDarkMode}
                                                        className={`relative inline-flex h-5 w-9 items-center rounded-full transition-colors ${
                                                            darkMode ? 'bg-blue-600' : 'bg-gray-200'
                                                        }`}
                                                    >
                                                        <span
                                                            className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
                                                                darkMode ? 'translate-x-5' : 'translate-x-0.5'
                                                            }`}
                                                        />
                                                    </button>
                                                    <Moon className="h-4 w-4" />
                                                    <span>Dark</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            onClick={handleSignOut}
                                            className={`block w-full text-left px-4 py-2 text-sm ${
                                                darkMode 
                                                    ? `${active ? 'bg-gray-700' : ''} text-gray-200` 
                                                    : `${active ? 'bg-gray-100' : ''} text-gray-700`
                                            }`}
                                        >
                                            Sign out
                                        </button>
                                    )}
                                </Menu.Item>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            )}
            {/* PowerFlow Modal */}
            {window.location.pathname !== '/change-password' && (
                <PowerFlowModal 
                    isOpen={isPowerFlowModalOpen}
                    onClose={() => setIsPowerFlowModalOpen(false)}
                    darkMode={darkMode}
                />
            )}
        </div>
    );
}