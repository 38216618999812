import React from 'react';
import { X, Check } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const QueueRow = ({ scenarioName, isSelected, location, power, onSelect }) => (
  <div className="grid grid-cols-4 px-4 py-3" style={{ gridTemplateColumns: '1fr 100px 1fr 100px' }}>
    <div className="text-sm font-medium">{scenarioName}</div>
    <div className="flex items-center justify-center">
      <button 
        onClick={() => onSelect(!isSelected)}
        className={`w-5 h-5 rounded border ${
          isSelected 
            ? 'bg-blue-500 border-blue-500 flex items-center justify-center' 
            : 'border-gray-300 dark:border-gray-600'
        }`}
      >
        {isSelected && <Check size={16} className="text-white" />}
      </button>
    </div>
    <div className="text-sm text-center">{location}</div>
    <div className="text-sm text-center">{power} kW</div>
  </div>
);

const EnergizationQueueModal = ({ isOpen, onClose, darkMode }) => {
  const [selectedScenarios, setSelectedScenarios] = React.useState({});

  const queueData = [
    {
      scenarioName: "Base Scenario",
      location: "N/A",
      power: "0",
    },
    {
      scenarioName: "Energization P1ULV4109-1",
      location: "Top of the Feeder",
      power: "50",
    },
    {
      scenarioName: "Energization P1ULV4109-2",
      location: "Top of the Feeder",
      power: "25",
    },
    {
      scenarioName: "Energization P1ULV4139",
      location: "Center of the Feeder",
      power: "75",
    },
    {
      scenarioName: "Energization P1ULV7543",
      location: "Bottom of the Feeder",
      power: "75",
    },
  ];

  const handleSelect = (scenarioName, isSelected) => {
    setSelectedScenarios(prev => ({
      ...prev,
      [scenarioName]: isSelected
    }));
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed -inset-4 bg-black bg-opacity-50 flex items-center justify-center z-[2000]"
          onClick={onClose}
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            transition={{ type: "spring", duration: 0.5, bounce: 0.3 }}
            className={`w-full max-w-4xl rounded-xl shadow-2xl ${
              darkMode ? 'bg-gray-800' : 'bg-white'
            } overflow-hidden mx-4`}
            onClick={e => e.stopPropagation()}
          >
            <div className={`px-6 py-4 flex justify-between items-center border-b ${
              darkMode ? 'border-gray-700' : 'border-gray-200'
            }`}>
              <h3 className={`text-xl font-semibold ${
                darkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Energization Scenarios
              </h3>
              <button
                onClick={onClose}
                className={`p-2 rounded-full transition-colors ${
                  darkMode 
                    ? 'hover:bg-gray-700 text-gray-400 hover:text-gray-200' 
                    : 'hover:bg-gray-100 text-gray-600 hover:text-gray-900'
                }`}
              >
                <X size={20} />
              </button>
            </div>
            
            <div className="p-6">
              <div className="max-h-[calc(100vh-16rem)] overflow-y-auto rounded-xl">
                {/* Header */}
                <div className={`grid grid-cols-4 px-4 py-2 sticky top-0 ${
                  darkMode ? 'bg-gray-700 text-white' : 'bg-gray-50 text-gray-900'
                }`} style={{ gridTemplateColumns: '1fr 100px 1fr 100px' }}>
                  <div className="text-sm font-medium">Scenario Name</div>
                  <div className="text-sm font-medium text-center">Status</div>
                  <div className="text-sm font-medium text-center">Location</div>
                  <div className="text-sm font-medium text-center">Energization</div>
                </div>

                {/* Queue Items */}
                <div className={`divide-y ${
                  darkMode ? 'divide-gray-700 text-gray-200' : 'divide-gray-200 text-gray-900'
                }`}>
                  {queueData.map((data, index) => (
                    <QueueRow 
                      key={index}
                      scenarioName={data.scenarioName}
                      isSelected={selectedScenarios[data.scenarioName] || false}
                      location={data.location}
                      power={data.power}
                      onSelect={(isSelected) => handleSelect(data.scenarioName, isSelected)}
                    />
                  ))}
                </div>
              </div>

              {/* Action Buttons */}
              <div className="mt-6 flex justify-end space-x-3">
                <button
                  onClick={onClose}
                  className={`px-4 py-2 rounded-lg transition-colors ${
                    darkMode 
                      ? 'bg-gray-700 hover:bg-gray-600 text-white' 
                      : 'bg-gray-200 hover:bg-gray-300 text-gray-800'
                  }`}
                >
                  Cancel
                </button>
                <button
                  onClick={onClose}
                  className={`px-4 py-2 rounded-lg transition-colors ${
                    darkMode 
                      ? 'bg-blue-600 hover:bg-blue-700 text-white' 
                      : 'bg-blue-500 hover:bg-blue-600 text-white'
                  }`}
                >
                  Apply
                </button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default EnergizationQueueModal;