import React, { useState } from 'react';
import { X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import ImageViewerModal from './imageViewerModal';

const AssistantPreviewModal = ({ isOpen, onClose, darkMode }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const previewImages = [
    {
      src: "./sampleAssistant.png",
      alt: "AI Assistant Chat Interface",
      caption: "Natural language queries and interactive chat interface"
    },
    {
      src: "./assistantChat.png",
      alt: "Real-time Analysis",
      caption: "Real-time network analysis and insights"
    },
    // {
    //   src: "/api/placeholder/600/300",
    //   alt: "Violation Detection",
    //   caption: "Automated violation detection and alerting"
    // },
    // {
    //   src: "/api/placeholder/600/300",
    //   alt: "Solution Recommendations",
    //   caption: "Smart recommendations for network optimization"
    // }
  ];

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  const handleNext = () => {
    setSelectedImageIndex((prev) => 
      prev < previewImages.length - 1 ? prev + 1 : prev
    );
  };

  const handlePrevious = () => {
    setSelectedImageIndex((prev) => 
      prev > 0 ? prev - 1 : prev
    );
  };

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[2000] p-4"
            onClick={onClose}
          >
            <motion.div
              initial={{ scale: 0.95, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.95, opacity: 0 }}
              transition={{ type: "spring", duration: 0.5, bounce: 0.3 }}
              className={`w-full max-w-5xl rounded-xl shadow-2xl ${
                darkMode ? 'bg-gray-800' : 'bg-white'
              } overflow-hidden`}
              onClick={e => e.stopPropagation()}
            >
              {/* Header */}
              <div className={`px-6 py-4 flex justify-between items-center border-b ${
                darkMode ? 'border-gray-700' : 'border-gray-200'
              }`}>
                <div>
                  <h3 className={`text-xl font-semibold ${
                    darkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    Coming Soon: AI Assistant
                  </h3>
                  <p className={`text-sm mt-1 ${
                    darkMode ? 'text-gray-400' : 'text-gray-600'
                  }`}>
                    Interactive AI-powered network analysis and recommendations
                  </p>
                </div>
                <button
                  onClick={onClose}
                  className={`p-2 rounded-full transition-colors ${
                    darkMode 
                      ? 'hover:bg-gray-700 text-gray-400 hover:text-gray-200' 
                      : 'hover:bg-gray-100 text-gray-600 hover:text-gray-900'
                  }`}
                >
                  <X size={20} />
                </button>
              </div>
              
              <div className="p-6">
                {/* Image Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {previewImages.map((image, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.1 }}
                      className="space-y-2"
                    >
                      <div 
                        className={`rounded-lg overflow-hidden border cursor-pointer ${
                          darkMode ? 'border-gray-700' : 'border-gray-200'
                        } hover:shadow-lg transition-shadow duration-300`}
                        onClick={() => handleImageClick(index)}
                      >
                        <img
                          src={image.src}
                          alt={image.alt}
                          className="w-full h-auto object-cover"
                        />
                      </div>
                      <p className={`text-sm text-center ${
                        darkMode ? 'text-gray-400' : 'text-gray-600'
                      }`}>
                        {image.caption}
                      </p>
                    </motion.div>
                  ))}
                </div>

                {/* Coming Soon Message */}
              <div className={`text-center p-4 rounded-lg ${
                darkMode ? 'bg-gray-700' : 'bg-gray-50'
              }`}>
                <p className={`text-lg font-medium ${
                  darkMode ? 'text-gray-200' : 'text-gray-900'
                }`}>
                  Our AI Assistant feature is currently in development
                </p>
                <p className={`mt-2 ${
                  darkMode ? 'text-gray-400' : 'text-gray-600'
                }`}>
                  Soon you'll be able to interact with your network data through natural language conversations
                </p>
              </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <ImageViewerModal
        isOpen={selectedImageIndex !== null}
        onClose={() => setSelectedImageIndex(null)}
        currentImage={previewImages[selectedImageIndex]}
        onNext={handleNext}
        onPrevious={handlePrevious}
        totalImages={previewImages.length}
        currentIndex={selectedImageIndex}
        darkMode={darkMode}
      />
    </>
  );
};

export default AssistantPreviewModal;